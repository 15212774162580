import {
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import AdminSideBar from "../../components/AdminSideBar";
import { useAuthState } from "../../hooks/context";
import {  Navigate, useNavigate } from "react-router-dom";
import {
  Add,
  Refresh,
  Edit,
  Delete,
  DoneSharp,
  Close,
} from "@mui/icons-material";
import axios from "axios";
import { updateDocs, deleteDocs, getAllDocs } from "../../helpers/Firebase";
import { EditDroneType, GridFields } from "../../@types/types";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import { AdminContainer } from "../../styles/styled";
import { toast, ToastContainer } from "react-toastify";

const API = process.env.REACT_APP_REST_API;

const StyledSearchBar = styled(TextField)`
width: { xs: 1, sm: 'auto' },
m: (theme) => theme.spacing(1, 0.5, 1.5),
'& .MuiSvgIcon-root': {
    mr: 0.5,
},
'& .MuiInput-underline:before': {
    borderBottom: 1,
    borderColor: 'divider',
},

`;

export default function Dashboard() {
  const user = useAuthState();
  const navigate = useNavigate();
  const [editable, setEditable] = useState(false);
  const [selectedDroneDoc,setSelectedDroneDoc] = useState<EditDroneType>()
  const [selectionModel,setSelectionModel] = useState<GridSelectionModel>()
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState<GridFields>([]);
  const [actionButtons, setActionButtons] = useState(<InactiveActionPage />);
  const editedRows = useRef<GridFields>([]);
  const deleteRows = useRef<GridFields>([]);
  const index = useRef(0);
  const [canDelete,setCanDelete] = useState(false)
  const [allDocs, setAllDocs] = useState<GridFields>([
    {
      id: -1,
      droneId: "",
      email: [""],
      name: ""
    },
  ]);
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", width: 60 },
    {
      field: "droneId",
      headerName: "Drone ID",
      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      maxWidth: 800,
      editable: editable,
    },
    {
      field: "name",
      headerName: "Organisation",
      width: 300,
      editable: false,
    },
  ];
  const updatePage = async () => {
      getAllDocs(user)
      .then((documents) => {
        const arr = [...documents.data];
        setAllDocs(arr);
        setFilteredRows(arr);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    updatePage();
  }, []);

  useEffect(() => {
    if (canDelete) {
      setActionButtons(<ActiveActionPage />);
    } else {
      setActionButtons(<InactiveActionPage />);
    }
  }, [canDelete]);

 
  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }
  const requestSearch = (searchValue: string) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const acceptedRows = allDocs.filter((row) => {
      return Object.keys(row).some((field) => {
        //iterate through each property
        return searchRegex.test(row[field as keyof typeof row].toString()); //accessing value of property
      });
    });

    setFilteredRows(acceptedRows);
  };


  useEffect(() => {
   
  }, [deleteRows]);

  return (
    <AdminContainer>
      <CssBaseline />
      <ToastContainer />
      <Box sx={{ display: "flex" }}>
        <AdminSideBar />
        <Box sx={{ height: "100vh", width: "100%", textAlign: "center" }}>
          <Typography variant="h3">Drones</Typography>
          {actionButtons}
          <StyledSearchBar
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? "visible" : "hidden",
                    borderRadius: "57%",
                    paddingRight: "1px",
                    margin: "0",
                    fontSize: "1.25rem",
                  }}
                  onClick={(e) => {
                    setSearchText("");
                    setFilteredRows(allDocs);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              ),
            }}
          />

          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={true}
            autoPageSize={true}
            loading={loading}
            onSelectionModelChange={(ids) => {
              setCanDelete(false)
              setSelectionModel(ids)
              const selectedIDs = new Set(ids); //create set from selected IDs
              const selectedRowData = allDocs.filter(
                (row) => selectedIDs.has(row.id) //if it has id, will update set with the row data
              );

              //console.log("Selected Row Data:", selectedRowData);
              deleteRows.current = selectedRowData; //useRef hook update
              if(ids.length != 0){ //Settings the last selected item as item to edit
                setCanDelete(true);

                setSelectedDroneDoc({
                  droneId : deleteRows.current[ids.length - 1].droneId,
                  emails : deleteRows.current[ids.length - 1].email,
                  name : deleteRows.current[ids.length - 1].name
                })

              }
            }}
            selectionModel = {selectionModel}
          />
        </Box>
      </Box>
    </AdminContainer>
  );

  function InactiveActionPage() {
    return (
      <Fragment>
        <Button onClick={() => updatePage()}>
          <Refresh />
        </Button>
        <Button
          onClick={() => {
            navigate("/sign-up");
          }}
        >
          {" "}
          <Add />
        </Button>
      </Fragment>
    );
  }
  function ActiveActionPage() {
    return (
      <Fragment>
        <Button
          onClick={() => {

            deleteDocs(deleteRows.current, user.uid, user.token)
              .then(() => console.log("Successfully deleted docs"))
              .catch((err) => console.log(err));
          }}
        >
          <Delete />
        </Button>
        <Button onClick={() => navigate("/edit-drone",{ state:selectedDroneDoc }) }>
          <Edit />
        </Button>
        <Button
          onClick={() => {
            //get Row IDs of the
            updateDocs(editedRows.current, user.uid, user.token)
              .then(() => {
                toast.success("Successfully updated!");
                setEditable(false);
                setCanDelete(false)
                setSelectionModel([])
              })
              .catch((err) => {
                console.log("Error in updating docs: ", err);
                toast.error("Error!")
              });
          }}
        >
          <DoneSharp />
        </Button>
        <Button
          onClick={() => {
            editedRows.current = [];
            deleteRows.current = [];
            setSelectionModel([])
            setCanDelete(false)
            setEditable(false);
          }}
        >
          <Close />
        </Button>
      </Fragment>
    );
  }
}
