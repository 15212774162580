import { Box, Container, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import DashboardImage from '../../assets/DashboardImage.jpg'
import Logo from '../../assets/aveticslogo-alpha.png'
import Typewriter from "typewriter-effect";
import '../../styles/dashboard.css'
export default function HeaderSection() {
  
      
      return (
        <Container maxWidth={false} disableGutters  >
      <Box sx={{position:"relative",textAlign:"center"}}>
        <div style={{position:"relative",backgroundColor:"#000000"}}>
              <img src={DashboardImage} style={{height:"100%",width:"100%",opacity:"80%"}}/>

        </div>
            <div style={{position:"absolute",top:"50%",left:"40%",textAlign:"center",marginLeft:"-23rem"}}>
            <div className="typewriter" style={{position:"relative"}}>
              <Typewriter
            onInit={(typewriter)=> {
            const Type = () => typewriter
            .typeString("BirdTrace")
            .pauseFor(3000)
            .deleteAll()
            .typeString("Avetics")
            .pauseFor(3000)
            .deleteAll()
            .start()
            
            Type();
            setInterval(() => Type(),3000);

            }}
            />
       </div>
              
                </div>
      </Box>
      </Container>
        )
}

//Box with logo 
//  <div style={{position:"absolute",backgroundColor:"black",borderStyle:"solid",height:"15rem",width:"50rem",opacity:"60%",marginLeft:"-23rem",marginTop:"-6rem",borderRadius:"20px"}} /> 
//<img src={Logo} style={{position:"absolute",marginLeft:"-20rem",marginTop:"-4rem",filter:"invert(100%) sepia(9%) saturate(2%) hue-rotate(263deg) brightness(114%) contrast(100%)"}} />
