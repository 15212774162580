import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/aveticslogo-alpha.png'
import {Link,animateScroll as scroll} from 'react-scroll'

export default function TopBar(props : any){
    const Navigate = useNavigate();
    return(
<Container
  disableGutters
  maxWidth={false}
>
<div style={{position:"relative"}}>
<AppBar
        color="inherit"
        elevation={10}
        sx={{position:"relative",top:"0", borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Avetics
          </Typography>
          <img src={logo} style={{height:"2%",width:"2%",opacity:"80%"}}/>
            <Button
              color="inherit"
              onClick={() => Navigate("/")}
              sx={{ my: 1, mx: 1.5,textDecoration:'none' }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              onClick={() => Navigate("/")}
              sx={{ my: 1, mx: 1.5,textDecoration:'none' }}
            >
            
            <Link to="pricing" spy={true} smooth={true} onClick = {() => {scroll.scrollTo(props.pricingRef.current,{smooth:'easeInOutQuint'})}}> 
            Pricing
            </Link>
            </Button>
          <Button onClick={()=> Navigate('/sign-in')  } variant="contained"  sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
  </div>  
  </Container>  
  )

}
