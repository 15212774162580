import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormik,
} from "formik";
import * as yup from "yup";
import { getAllUsers, registerAccount, registerDrone } from "../../helpers/Firebase";
import { useAuthState } from "../../hooks/context";
import { toast, ToastContainer } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { DroneFormData, UserGridField, EditDroneType} from "../../@types/types";
import styled from "styled-components";
import { ConstructionOutlined, NavigateNextOutlined, Navigation, PasswordSharp } from "@mui/icons-material";
import { EditDroneValidationSchema as ValidationSchema } from "../../helpers/ValidationSchema";
import Autocomplete from "@mui/material/Autocomplete";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.avetics.com/">
        Avetics
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const FormCSS = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;


export default function EditDrone(props: any) {
  //Error msg OR successful
  const [error, setError] = useState<Error>();
  const [users, setUsers] = useState([""]);
  const user = useAuthState();
  const navigate = useNavigate();
  const {state} = useLocation();


  const selectedDroneDoc : any= state
  const {droneId,emails,name} : EditDroneType= selectedDroneDoc 

  //onSubmit : (values) => {
  const handleSubmit = (values: EditDroneType) => {
    console.log("Submitting :", values);
    registerDrone(
      values.name,
      values.emails.map((email : string) => email.toLowerCase()),
      [values.droneId],
      user.token,
      user.uid
    )
      .then(() => {
        registerStatusMsg("Successful Update");
      })
      .catch((e: Error) => {
        setError(e);
        if (error) registerStatusMsg(error.toString());
        else
          registerStatusMsg(
            "Undefined Error! Please contact System Administrator"
          );
      });
  };
  const registerStatusMsg = (msg: string) =>
    toast(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); //success notification

  useEffect(() => {
    getAllUsers(user.uid, user.token)
      .then((documents) => {
        const arr = [
          ...documents.data.map((document: UserGridField) => document.email),
        ];
        setUsers(arr);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <ToastContainer />
        <Typography component="h1" variant="h5">
          Edit Drone 
        </Typography>
        <Formik
          initialValues={{
            emails: [""],
            droneId: droneId,
            name: name
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values : EditDroneType)=> handleSubmit(values)}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <Form>
                <FormCSS>
                <TextField

                 placeholder = {droneId}
                 InputProps={{
                  readOnly: true,
                }}
                />

                  <ErrorMessage name="droneids" />

                  <div>
                    <Autocomplete
                      multiple
                      id="email-boxes"
                      options={users}
                      sx={{ minWidth: 400, maxWidth: 800 }}
                      defaultValue={emails}
                      renderInput={(params) => (
                        <TextField {...params} label={`Emails`} />
                      )}
                      onChange={(event, value) => values.emails = value} // prints the selected value
                    />
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <TextField 
                    placeholder={name}

                    InputProps={{readOnly: true,}}
                     />
                  </div>

                  <ErrorMessage name="name" />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
                </FormCSS>
              </Form>
            );
          }}
        </Formik>

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => navigate("/admin/users")}
        >
          Back
        </Button>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
