import React,{ useState,useEffect } from 'react';
import { User } from "firebase/auth";
import { useAuthState } from '../hooks/context';
import { Navigate} from 'react-router-dom';
import axios from 'axios';
import { FireBaseToken } from '../@types/types';

type Props = {
	children : JSX.Element;
}


//Affect all children
export default function AdminPrivateRoute ({children}:Props) { 
{
	//State to change when user logged in or logged out
	const user = useAuthState()
	if(user.uid == "0tQyvRoQZoUpaL5BNDlhUraLAfp1")
		return children;

	return <Navigate to="/" />
	}

}




