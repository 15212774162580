import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormik,
} from "formik";
import * as yup from "yup";
import { getAllUsers, registerAccount, registerDrone } from "../helpers/Firebase";
import { useAuthState } from "../hooks/context";
import { toast, ToastContainer } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { DroneFormData, UserGridField, UserGridFields } from "../@types/types";
import styled from "styled-components";
import { PasswordSharp } from "@mui/icons-material";
import { DroneSignUpValidationSchema as ValidationSchema } from "../helpers/ValidationSchema";
import Autocomplete from "@mui/material/Autocomplete";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.avetics.com/">
        Avetics
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const FormCSS = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export default function SignUp(props: any) {
  //Error msg OR successful
  const [error, setError] = useState();
  const [users, setUsers] = useState([""]);
  const user = useAuthState();
  const navigate = useNavigate();


  //onSubmit : (values) => {
  const handleSubmit = async (values: DroneFormData, resetForm : any) => {

    //console.log("Submitting :", values);
    registerDrone(
      values.name,
      values.emails.map((email : string) => email.toLowerCase()),
      values.droneids,
      user.token,
      user.uid
    )
      .then((result : any) => {
        result.data.arr.forEach((item : string[] )=> {
          if(item[0] != "success")
            toast.error(item[1])
          else
            registerStatusMsg(item[1])

      })
      })
      .catch((e: any) => {
        console.log(e)
        setError(e)
        toast.error("Undefined Error! Please Contact System Administrator")
      });
  };
  const registerStatusMsg = (msg: string) =>
    toast(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); //success notification

  useEffect(() => {
    getAllUsers(user.uid, user.token)
      .then((documents) => {
        const arr = [
          ...documents.data.map((document: UserGridField) => document.email),
        ];
        setUsers(arr);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <ToastContainer />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Formik
          initialValues={{
            name: "",
            emails: [""],
            droneids: [""],
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values : DroneFormData , {resetForm} : any) => {handleSubmit(values,resetForm)}}
        >
          {(formik) => {
            const { values ,resetForm} = formik;
            return (
              <Form>
                <FormCSS>
                  <FieldArray name="droneids">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const droneIds = form.values.droneids;

                      return (
                        <div>
                          {droneIds.map((droneId: string, index: number) => (
                            <div key={index}>
                              <Field
                                name={`droneids[${index}]`}
                                placeholder={`Drone Id ${index + 1}`}
                              />
                              <Button onClick={() => push("")}>+</Button>
                              <Button
                                onClick={() => {
                                  if (droneIds.length > 1) remove(index);
                                }}
                              >
                                -
                              </Button>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  </FieldArray>

                  <ErrorMessage name="droneids" />

                  <div>
                    <Autocomplete
                      multiple
                      id="email-boxes"
                      options={users}
                      sx={{ minWidth: 400, maxWidth: 800 }}
                      renderInput={(params) => (
                        <TextField {...params} label={`Emails`} />
                      )}
                      onChange={(event, value) => values.emails = value} // prints the selected value
                    />
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <Field name="name" placeholder="Organisation Name" />
                  </div>
                  <ErrorMessage name="name" />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign Up
                  </Button>
                </FormCSS>
              </Form>
            );
          }}
        </Formik>

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => navigate("/admin/users")}
        >
          Back
        </Button>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
