import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import SideBar from '../components/SideBar'
import { Card, CardContent, CardHeader, Container, stepClasses } from '@mui/material';

type Steps = 
  { 
      title : string,
      content: string
  } []


export default function Dashboard()
{
  const steps : Steps = [
    {
      title : "How can I approve another user to use my drone?",
      content: "Please contact our customer support to approve the user for you."
    },

    {
      title: "How to use our Service?",
      content: "Open your FlyitSafe Application and enter your necessary details. Enable the option 'Send Data via Telemetry API'. You can start your flight and check the top left for a 6 digit code as your flight number (This is the UASID) "

    },
    {
      title: "How do I know that it is working?",
      content: "You will be able to see your drone icon move in the FlyitSafe application"

    }

  
  ]

	return (
    <Container 
      maxWidth={false}
    >
    <Box sx={{display:"flex",flexGrow:0,flexBasis:0}}>
        <SideBar />
        <Box sx={{ marginTop: "2rem",height : '75vh',width : '100%', display:"flex",gap:"20px",flexDirection:'column' }}>
        <CssBaseline />
     <React.Fragment>
          {
      
          steps.map((step : Steps[0],index : number) => {
            return(
              <li key={index} style={{listStyleType : "none"}}>   
              <Card sx={{ maxWidth: '75vw', minHeight:"2rem",maxHeight:'100%' }} elevation={10}>
                <CardHeader title={step.title} />
                <CardContent>{step.content}</CardContent>
              </Card>
            </li>
            )
      })}
  </React.Fragment>

        </Box>
    </Box>
    </Container>
  );

}