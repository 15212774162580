import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import BuildIcon from "@mui/icons-material/Build";
import ComputerIcon from "@mui/icons-material/Computer";
import BarChartIcon from "@mui/icons-material/BarChart";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloudIcon from "@mui/icons-material/Cloud";
import MeassageIcon from "@mui/icons-material/Message";
import CancelIcon from "@mui/icons-material/Cancel";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeatureCard from "./FeatureCard";
import { useTheme } from "@mui/material/styles";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output : any, key : any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

function calculateSpacing(width : number, theme : any) {
    const currentWidth = theme["breakpoints"]["values"][width];
    if (currentWidth >= theme["breakpoints"]["values"]["lg"]) {
      return 10;
    }
    if (currentWidth >= theme["breakpoints"]["values"]["md"]) {
      return 8;
    }
    if (currentWidth >= theme["breakpoints"]["values"]["sm"]) {
      return 6;
    }
    return 4;
  }
  
const iconSize = 30;
const mdDelayBase = 0;
const features = [
  {
    color: "#00C853",
    headline: "Ease of Access",
    text: "Fast Data Transmission, Limited Network Compatibility",
    icon: <AccessibilityIcon style={{ fontSize: iconSize }} />,
    mdDelay: mdDelayBase,
    smDelay: "1000",
  },
  {
    color: "#6200EA",
    headline: "Trackers no longer necessary",
    text: "With those trackers gone, your drones operate at much lighter loads and increased speeds",
    icon: <FitnessCenterIcon style={{ fontSize: iconSize }} />,
    mdDelay: mdDelayBase+100,
    smDelay: "200",
  },
  {
    color: "#0091EA",
    headline: "Collaborate in Teams",
    text: "Track your drones with your team",
    icon: <GroupIcon style={{ fontSize: iconSize }} />,
    mdDelay: mdDelayBase+200,
    smDelay: "1200",
  },
  {
    color: "#d50000",
    headline: "Low Power Data Transmission",
    text: "Power your data with lower transmission overhead",
    icon: <AccountTreeIcon style={{ fontSize: iconSize }} />,
    mdDelay: mdDelayBase+300,
    smDelay: "1200",
  },
  {
    color: "#DD2C00",
    headline: "Customised Experience",
    text: "Personalised Service to Client Interaction",
    icon: <SupportAgentIcon style={{ fontSize: iconSize }} />,
    mdDelay: mdDelayBase+400,
    smDelay: "0",
  },

];

function FeatureSection(props : any) {
  const theme  = useTheme();
  const width = useWidth();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container maxWidth="md">
    <Box sx={{marginTop:"15rem"}}>
          <Grid container spacing={calculateSpacing(width, theme)}>
            {features.map((element) => (
              <Grid
                item
                xs={6}
                md={4}
                data-aos="zoom-in-up"
                data-aos-delay={isWidthUpMd ? element.mdDelay : element.smDelay}
                key={element.headline}
                sx={{borderRadius:"50px"}}
              >
                <FeatureCard
                  Icon={element.icon}
                  color={element.color}
                  headline={element.headline}
                  text={element.text}
                />
              </Grid>
            ))}
          </Grid>
    </Box>
    </Container>
  );
}

FeatureSection.propTypes = {};

export default FeatureSection;
