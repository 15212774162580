import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

export function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.avetics.com/">
          Avetics
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default function Footer() {
    const footers = [
        
        {
          title: 'Legal',
          description: ['Privacy policy', 'Terms of use'],
          onclick:['https://www.avetics.com/privacy','https://www.avetics.com/terms-of-use']
        },
      ];
  
  
    return (
      <Container
      maxWidth={false}
      component="footer"
      sx={{
        mt: 8,
        py: [3, 6],
        textAlign:"center"
      }}>

      <Grid justifyContent="center">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h3" color="text.primary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map((item,idx) => (
                <li key={item}>
                  <Link href={footer.onclick[idx]} variant="subtitle1" color="inherit" sx={{textDecoration:'none'}}>
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Container>
    )
}
