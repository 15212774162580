import React, { useState, useEffect, useContext, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Checkbox, Container, FormControlLabel } from "@mui/material";
import logo from "../assets/aveticslogo.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TopBar from "../components/TopBar";
import { useAuthState } from "../hooks/context";
import { loginUser } from "../hooks/actions";
import { useAuthDispatch } from "../hooks/context";
import CopyRight from "../components/CopyRight";
import {SignInValidationSchema as ValidationSchema} from "../helpers/ValidationSchema";
import { Field, Form, Formik } from "formik";
import { LoginPayload } from "../@types/types";
import "../styles/global.css";
import { toast, ToastContainer } from "react-toastify";

export default function SignIn() {
  const dispatch = useAuthDispatch();
  const user = useAuthState();
  const Navigate = useNavigate();
  const [remember, setRemember] = useState(true);

  useEffect(() => {
    loginUser(dispatch);
  }, []);
  useEffect(() =>{
    if (user.uid && user.token) {
    if (user.uid == "0tQyvRoQZoUpaL5BNDlhUraLAfp1")
      Navigate("/admin/dashboard");
    else Navigate("/pilot");
  }} ,[user])
  const handleSubmit =  async (values: LoginPayload,setSubmitting : any) => {
    const { email, password, remember } = values;
    setSubmitting(true)

    loginUser(dispatch, {
      email: email,
      password: password,
      remember: remember,
    })
    .then(() =>{

    
    setSubmitting(false)
    toast.error("Incorrect Username/Password")} )
  .catch((err : Error) =>{
    console.log("Undefined Error")
   } )
  
      };

  return (
    <>
      <TopBar />
      <ToastContainer/>
      <Container maxWidth={false}>
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.2rem",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src={logo} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik
            initialValues={{ email: "", password: "", remember: remember }}
            validationSchema={ValidationSchema}
            onSubmit={(values : LoginPayload,{setSubmitting}:any) => handleSubmit(values,setSubmitting)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setSubmitting
            }) => (
              <Form onSubmit={handleSubmit}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  {" "}
                  Email
                </Typography>
                <Field
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                <span style={{ marginLeft: 10 }}>
                  {errors.email && touched.email && errors.email}
                </span>
                <div className="in-field">
                  <Typography variant="h6" sx={{ fontSize: "20px" }}>
                    {" "}
                    Password
                  </Typography>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && errors.password}
                </div>

                <div>
                  <FormControlLabel
                    label="Remember me"
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={() => {
                          remember ? setRemember(false) : setRemember(true);
                        }}
                      />
                    }
                  />

                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("/forgot-password")}
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div style={{ marginTop: "1.2rem" }}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{ position: "relative" }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
        <CopyRight sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}
