import React, { useState, useMemo, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode, Typography } from "@mui/material"; //Type for them
import { AuthProvider } from "./hooks/context";
import { MaterialUISwitch } from "./components/MaterialUISwitch";
import AppNavigation from "./navigation/AppNavigation";
import AOS from 'aos'
import '../node_modules/aos/dist/aos.css';

//Consider using MaterialUISwitch as Context
function App() {
  const [mode, setMode] = useState("light");

  useEffect(() => {
    AOS.init();
    AOS.refresh()
  }, []);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode as PaletteMode,
        },
        typography: {
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      }),
    [mode]
  );
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
            <AppNavigation />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
