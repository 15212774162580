import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { updateUasid, verifyUserDrone } from "../helpers/Firebase";
import JSBridge from "./JSBridge";
import axios, { AxiosResponse } from "axios";
import { useAuthState } from "../hooks/context";
import { CircularProgress, Container } from "@mui/material";


const HOST = process.env.REACT_APP_SERVER_IP_ADDR;

const Pilot = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [isAuthorized,setIsAuthorized ] = useState(false)
  const [pageState,setPageState] = useState(<LoadingPage/>)
  const user = useAuthState()

  const handleVerification = async () : Promise<boolean> => 
  {
   try {
    //Request for the list of user emails that are authenticated with this droneID
    const axiosResponse : AxiosResponse = await verifyUserDrone(user.uid,user.token,JSON.parse(window.djiBridge.platformGetAircraftSN()).data)
    if(axiosResponse.status == 200)
        return true;
    else 
      return false;
    }
    catch(error : any) {
      console.log(error);
      return false;
    }
  
  }

  useEffect(() => {
      
      if(!(window as any).djiBridge)
      {
        setPageState(<IncompatibleDevice/>)
        return;
      }
      if(pageState != (<IncompatibleDevice/>))
      {
      handleVerification()
        .then((result : any) => {
        console.log(result)
        if(result == true)
          {
            setIsLoading(false)
            setIsAuthorized(true)
            console.log("[+] Drone is Authorised to use Service")
            setPageState(<AuthorizedPage/>)
          }
        else
          {
            setIsLoading(false);
            setIsAuthorized(false);
            setPageState(<UnauthorizedPage/>)
          }
        }
      )
    .catch((err) => {
      console.log(err)
      })
    }
  }
, [])

return (<Container 
sx={{display:"flex"}}
maxWidth={false}
>
    {pageState}
  </Container>)

}

const AuthorizedPage = () => {
  const [isSwitch, setIsSwitch] = useState<boolean>(false);
  const [uasid, setUasid] = useState<string>("");
  const label = { inputProps: { "aria-label": "Switch" } };
  const user = useAuthState()

  const handleSwitchChange = () => {
    setIsSwitch(!isSwitch);
    if (isSwitch === false) {
        updateUasid(uasid,JSON.parse(window.djiBridge.platformGetAircraftSN()).data,user.token)
        .then((res : any) => "[+] Successful update to REST")
        .catch((err:Error)=> console.log(err) )
    } 
  };

  return (
    <>
      <SideBar />
      <Box sx={{ position: "relative", marginLeft: "10em" }}>
        <CssBaseline />
        {//Comment out when DEBUGGING 
        <JSBridge switch={isSwitch} />
        }
        <div>
          <Typography variant="h3" >
            Flysafe Connection
          </Typography>
       
            <Typography
              variant="body1"
              display="block"
            >
           Wait for the message "Connected" in the Connection Status field.
            Then, hit send to send your telemetry data to DSTA API! Send
            your Telemetry Data straight to CFMS endpoint! If data is still not transmitted, try switching it off and on again.
          </Typography>
          <TextField
            id="uasid"
            label="UASID"
            placeholder="6-digit-code"
            variant="outlined"
            required
            onChange={(e) => setUasid(e.target.value)}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <Switch
            disabled={uasid.length != 6}
            {...label}
            onChange={handleSwitchChange}
          />
        </div>
      </Box>
    </>
  );
      }

const LoadingPage =() => {
  return (
  <>
      <Box style={{display:"flex"}}>
    <SideBar/>
      <div style={{position:"absolute",top:"50%",left:"50%"}}>
      <CircularProgress />
      </div>
    </Box>
  </>)
}


const UnauthorizedPage = () => 
{
  return( 
    <>
    <SideBar />
    <Typography> Not Authorized to use this drone! Please request System Administrator for further information</Typography>)
    </>
  )

}

const IncompatibleDevice = () => {
  return (
    <>
      <SideBar />
      <Box sx={{ position: "relative", marginLeft: "10em",alignItems:'center'}}>
        <Typography variant="h4">Please View this on your DJI Pilot Controller! </Typography>
      </Box>
    </>
  );
}

export default Pilot;

