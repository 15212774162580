import { ContactPageSharp } from '@mui/icons-material'
import React,{Dispatch, useId, useReducer} from 'react'
import { LoginState } from '../@types/types'

export const initialState = 
{
    uid:"",
    token:""
}

export const AuthReducer = (initialState:any,action:{type:string,payload:any,error:Error,remember:boolean}) => 
{
switch(action.type)
{
    case "REQUEST_LOGIN":
        const uid : string | null = localStorage.getItem("uid")
        const token : string | null = localStorage.getItem("token")
        if(uid && token)
            return {
                ...initialState,
                uid:uid,
                token:token
            }
        return{
            ...initialState
        }
    case "LOGIN_SUCCESS":
        if(action.remember){
        localStorage.setItem("uid",action.payload.data.uid)
        localStorage.setItem("token",action.payload.data.access_token)
        }
        return{
            ...initialState,
            uid: action.payload.data.uid,
            token: action.payload.data.access_token
        };
    case "LOGOUT":
        localStorage.removeItem('uid')
        localStorage.removeItem('access_token')
        return{
            ...initialState,
            uid:"",
            token:""
        }
    case "LOGIN_ERROR":
        return {
            ...initialState,
            loading: false,
            errorMessage: action.error
        };

        default:
        throw new Error(`Unhandled action type: ${action.type}`);

}
}
