import React, { Fragment, useRef } from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import FeatureSection from './FeatureSection'
import HeaderSection from './HeaderSection'
import Pricing from './Pricing'
import { Navigate } from 'react-router-dom'

export default function Home() {
  const pricingRef = useRef<null | HTMLDivElement>(null)
  if(window.djiBridge)
    return(<Navigate to="/sign-in"/>)

  return(
   
    <Fragment>
      <TopBar pricingRef = {pricingRef}/>
      <HeaderSection/>
      <FeatureSection/>
      <div id="pricing" ref={pricingRef}>
        <Pricing/>
      </div>
      <Footer/>

    </Fragment>
  )
}
