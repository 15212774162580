import React, { Fragment, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { sendPasswordReset } from "../helpers/Firebase";
import { auth } from "../helpers/Firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TopBar from "../components/TopBar";
import logo from "../assets/aveticslogo.png";
import CopyRight from "../components/CopyRight";
import {ForgotPasswordValidationSchema as ValidationSchema} from "../helpers/ValidationSchema";
import { Field, Form, Formik } from "formik";

export default function ForgotPassword() {
  const Navigate = useNavigate();
  const successMsg = (msg: string) =>
    toast(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); //success notification

  const failedMsg = () =>
    toast.error("Error! Please try again later or refresh the page", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleSubmit = (values: { email: string },setSubmitting : any) => {
    sendPasswordReset(values.email)
      .then((result: any) => {
        console.log(result);
        if (result.status == 200) {
          successMsg(
            "Password Reset Sent! Please check your email for further instructions"
          );
          setSubmitting(false)

        } else failedMsg();
      })
      .catch((error: Error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
        setSubmitting(false)
      });
  };

  return (
    <>
      <CssBaseline />

      <TopBar />
      <Container maxWidth={false}>
        <ToastContainer />
        <Box sx={{ textAlign: "center", mt: "3rem" }}></Box>

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src={logo} />
          <Typography variant="h5" component="h5">
            Forgot Password
          </Typography>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={ValidationSchema}
            onSubmit={(values, {setSubmitting}) => handleSubmit(values,setSubmitting)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit}>
                <Typography variant="h6" sx={{ fontSize: "20px",marginTop:"2rem" }}>
                  Email
                </Typography>
                <Field
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />

                <span style={{ marginLeft: 10 }}>
                  {errors.email && touched.email && errors.email}
                </span>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3 }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <Button
            type="button"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => Navigate("../sign-in")}
          >
            Back
          </Button>
        </Box>
        <CopyRight sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}
