import * as yup from "yup";

export const SignInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
    ,
  password: yup
    .string()
    .required("Enter your password")
    .required("Password is required")
});

export const ForgotPasswordValidationSchema= yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
});

export const UserSignUpValidationSchema = yup.object().shape({
  users : yup.array().of(
    
    yup.object().shape({
        email: yup
        .string().required('Email is required')
        .email('Invalid Email Format')
          ,
        password: yup
          .string()
          .required('Password is required')
          .min(8, 'Password should be of minimum 8 characters length')
    
    }))

})

export const DroneSignUpValidationSchema = yup.object().shape({

  droneids: yup
  .array()
  .of(yup.string())
  ,
  name : yup
  .string()

})

export const EditDroneValidationSchema = yup.object().shape({
 
  emails: yup
    .array()
    .required('Email is required'),
  droneid: yup
  .string(),
  name:yup
  .string()
,
});