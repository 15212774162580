import React, { useState } from 'react';
import { User } from 'firebase/auth'
import { AuthReducer,initialState } from './reducer';
import { LoginPayload, LoginState} from '../@types/types';



const AuthStateContext = React.createContext<LoginState>(initialState); 
const AuthDispatchContext = React.createContext(AuthReducer);

export function useAuthState()
{
    const context = React.useContext(AuthStateContext)
    if( context == undefined )
    {
        throw new Error("useAuthState MUST be used within Auth Provider")
    }
    return context;
}

export function useAuthDispatch() 
{
    const context = React.useContext(AuthDispatchContext)
    if(context==undefined)
    {
        throw new Error ("useAuthDispatch MUST be used within AuthProvider")    
    }
    return context;
}

export const AuthProvider = ({children} : any) =>
{

    const [user, dispatch] = React.useReducer(AuthReducer,initialState)
     return(
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                         {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>


     )

}