import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../hooks/actions';
import { SwipeableDrawer } from '@mui/material';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { useAuthDispatch } from '../hooks/context';
import MenuIcon from '@mui/icons-material/Menu';
import { Nav } from 'reactstrap';

export default function SideBar()
{
	const Navigate = useNavigate();

  const [isOpen,setIsOpen] = useState(false)
  const dispatch = useAuthDispatch()

  type SideBarContent = 
  {
    title:string,
    color:string,
    handleClick: () => void,

  }
  const content : SideBarContent [] = [
    {
      title:'CFMS Connection',
      color:'inherit',
      handleClick: () => {Navigate('/pilot')}
    },
    {
      title:'My Profile',
      color:'inherit',
      handleClick: () => {Navigate('/profile')}
    },
    {
      title:'Help',
      color:'inherit',
      handleClick: () => {Navigate('/help')}

    },
    {
      title:'Log Out',
      color : 'inherit',
      handleClick: () => {logOutUser(dispatch);Navigate('/')}
      
    }
  ]
  /*
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
     useEffect(() => {
      handleResize();
     },[window.innerWidth])
     */

  return(
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
  <Button
   onClick = {() => setIsOpen(true)}
   size="small"
   color="inherit"
   sx= {{position:"relative",alignItems:'flex-start',flex:0,height:'2rem',width:'auto'}}
  >
    <MenuIcon
        />
    </Button>

    <Drawer
      sx={{
        width: '10em',
        '& .MuiDrawer-paper': {
          width: '10em',
          boxSizing: 'border-box',
          justifyContent: 'top',
          gap: '2em',
          flexGrow : 1,
          position : 'fixed'
        },
      }}
      variant="temporary"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchor='left'
    >

    {content.map((item : SideBarContent,index : number) => 
    (
      <li key={index}>
      <Typography variant="h1" align='center'>
    <Button 
    color='inherit'
    onClick = {() => {item.handleClick()}}
    >
      {item.title}
    </Button>
          </Typography>
      </li>

    )
    )}

      </Drawer>
   
  </Box>
  )

}