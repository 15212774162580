import { Box, Card, CircularProgress, Container, CssBaseline, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import {useAuthState} from '../hooks/context'
import {getUserData} from '../helpers/Firebase'
import { UserData } from '../@types/types'
import { PowerInputOutlined } from '@mui/icons-material'
import userEvent from '@testing-library/user-event'
export default function MyProfile() {
      //Provider a Full page of information for users to check
    //1. Indicate Name of Organisation
    //2. Grid of DroneIDs and Approved Drones
    const {uid,token} = useAuthState()
    const [email, setEmail] = useState("")
    const [creationTime,setCreationTime] = useState("")
    const [droneIds,setDroneIds] = useState<string[]>([]);
    const [isLoading,setIsLoading] = useState(true)
    const [pageState,setPageState] = useState(<>{}</>)
   
   getUserData(token,uid)
   .then((response) => {
      const user : UserData= response.data
      setEmail(user.email);
      setCreationTime(user.creationTime)
      setDroneIds([...user.droneIds])
      //console.log(user)
      
   })
   // const {organisation,email,droneIds} = getUserData(token,uid); //we can query userInfo from querying firebase

   
   const cardContents = droneIds.map((droneId : string,index : number) => (

    <React.Fragment key={index} >
      <Card  sx={{
          width:"40vw",
          textAlign:"left",
          padding:"10px"
      }} >
          <div >
          <Typography variant = "h6">{droneId} </Typography>
          </div>

     </Card>
    </React.Fragment>

    ))
  

    const LoadingPage = () => (
            <Container
            maxWidth={false}
            >
              <Box sx={{display:"flex"}}>
                <CssBaseline/>
                <SideBar/>
                <Box sx={{
                  marginLeft:"3rem",
                  justifyContent:"center",
                  display: "flex",
                  flexDirection:"column",
                  gap:"4rem",
                  alignItems:"center",
                  flexGrow:10
                }}>
                   <Typography variant = "h3">My Profile</Typography> 
                    <Card  sx={{
                      width:"40vw",
                      textAlign:"left",
                      padding:"10px"
                  }}  >
                      <CircularProgress />
                  </Card>
                   <Typography variant = "h4" sx={{marginTop:"2rem"}}>Drones Allowed for User</Typography>
                   <CircularProgress/>
                </Box>
              </Box>
            
        
            </Container>

    )
    const LoadedPage = () => ( <Container
      maxWidth={false}
      >
        <Box sx={{display:"flex"}}>
          <CssBaseline/>
          <SideBar/>
          <Box sx={{
            marginLeft:"3rem",
            justifyContent:"center",
            display: "flex",
            flexDirection:"column",
            gap:"4rem",
            alignItems:"center",
            flexGrow:10
          }}>
             <Typography variant = "h3">My Profile</Typography> 
              <Card  sx={{
                width:"40vw",
                textAlign:"left",
                padding:"10px"
            }}  >
             <Typography variant="h6"> Email Used: {email}</Typography>
             <Typography variant="h6"> Time Created: {creationTime} </Typography>
            </Card>
             <Typography variant = "h4" sx={{marginTop:"2rem"}}>Drones Allowed for User</Typography>
             <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
             {cardContents}  
             </div>
          </Box>
        </Box>
      
  
      </Container>)

  useEffect(() => {  
    if(isLoading)
    setPageState(<><LoadingPage/></>)},[])
  
  useEffect(() => {if(email)
        setPageState(<LoadedPage/>)},[email])
  return (
   <>{pageState}</>
  )

  

}


/*     
           <Typography variant="h6"> Organisation Name : {user.organisation} </Typography>
           */
