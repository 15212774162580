import axios from 'axios';
import { LoginPayload } from '../@types/types';
const API=process.env.REACT_APP_REST_API

export async function loginUser(dispatch : any,loginPayload : LoginPayload = {email:"",password:"",remember:true})
{
    try{
        const {email,password,remember} = loginPayload
        dispatch({type:'REQUEST_LOGIN'})

        if(API && email && password){   
        const data = (await axios.post(API+"api/signin",{
            headers:
        {
            'Content-Type': 'application/json'
        },
        email,
        password
        }))
        
        dispatch({type:'LOGIN_SUCCESS',payload:data,remember:remember})
        console.log("Retrieved User!")
        return data;
    }
}
    catch(error)
    {
        dispatch({ type: 'LOGIN_ERROR', error});
        return;
       

    }


}
export function logOutUser(dispatch:any)
{
    dispatch({ type:'LOGOUT'})
    console.log("Logging out...")


}
