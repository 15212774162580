import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";


function shadeColor(hex : string, percent : number) {
  const f = parseInt(hex.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

function FeatureCard(props : any) {
  const {  Icon, color, headline, text } = props;
  return (
    <Fragment>
    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",flexGrow:0}}>
      <div
        style={{
          color: color,
          backgroundColor: shadeColor(color, 0.8),
          borderRadius:100,
          height:50,
          width:50,
          padding: 12,
        }
      }
      >
          <div style={{position:"relative"}}>
        {Icon}
          </div>
      </div>
      <Typography variant="h5" paragraph>
        {headline}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {text}
      </Typography>
    </div>
    </Fragment>
  );
}

FeatureCard.propTypes = {
  Icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default FeatureCard;
