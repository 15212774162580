import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AdminSideBar from "../../components/AdminSideBar";
import { useAuthState } from "../../hooks/context";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Add,
  Refresh,
  Edit,
  Delete,
  DoneSharp,
  Close,
} from "@mui/icons-material";
import axios from "axios";
import { updateDocs, deleteDocs, deleteUsers, getAllUsers } from "../../helpers/Firebase";
import { GridFields, UserGridFields } from "../../@types/types";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import { AdminContainer } from "../../styles/styled";

const API = process.env.REACT_APP_REST_API;

const StyledSearchBar = styled(TextField)`
width: { xs: 1, sm: 'auto' },
m: (theme) => theme.spacing(1, 0.5, 1.5),
'& .MuiSvgIcon-root': {
    mr: 0.5,
},
'& .MuiInput-underline:before': {
    borderBottom: 1,
    borderColor: 'divider',
},

`;

export default function Users() {
  const user = useAuthState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState<UserGridFields>([]);
  const [editedRows, setEditedRows] = useState<UserGridFields>([]);
  const [deleteRows, setDeleteRows] = useState<UserGridFields>([]);
  const [userUid, setUserUid] = useState<string[]>([]);
  const [allDocs, setAllDocs] = useState<UserGridFields>([
    {
      id: -1,
      email: "",
      uid: "",
    },
  ]);
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", width: 60 },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "uid",
      headerName: "User UID",
      width: 300,
      editable: false,
    },
  ];
  const setUsersArr = async () => {

      getAllUsers(user.uid,user.token)
      .then((documents) => {
        const arr = [...documents.data];
        setAllDocs(arr);
        setFilteredRows(arr);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setUsersArr();
  }, []);

  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }
  const requestSearch = (searchValue: string) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const acceptedRows = allDocs.filter((row) => {
      return Object.keys(row).some((field) => {
        //iterate through each property
        return searchRegex.test(row[field as keyof typeof row].toString()); //accessing value of property
      });
    });

    setFilteredRows(acceptedRows);
  };

  return (
    <AdminContainer
    >
      <Box sx={{ display: "flex" }}>
        <AdminSideBar />

        <Box sx={{ height: "100vh", width: "100%", textAlign: "center" }}>
          <Typography variant="h3">Users</Typography>
          <ActiveActionPage />
          <StyledSearchBar
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? "visible" : "hidden",
                    borderRadius: "57%",
                    paddingRight: "1px",
                    margin: "0",
                    fontSize: "1.25rem",
                  }}
                  onClick={(e) => {
                    setSearchText("");
                    setFilteredRows(allDocs);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              ),
            }}
          />
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
            autoPageSize={true}
            loading={loading}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = allDocs.filter(
                (row) => selectedIDs.has(row.id) //if it has id, will update set with the row data
              );
              setDeleteRows(selectedRowData);
            }}
          />
        </Box>
      </Box>
    </AdminContainer>
  );

  function ActiveActionPage() {
    return (
      <>
        <Button onClick={() => setUsersArr()}>
          <Refresh />
        </Button>
        <Button
          onClick={() => {
            navigate("/user-sign-up");
          }}
        >
          {" "}
          <Add />
        </Button>

        <Button
          onClick={() => {
            deleteUsers(user.uid, user.token, deleteRows)
              .then(() => console.log("Successfully deleted docs"))
              .catch((err) => console.log(err));
          }}
        >
          <Delete />
        </Button>
      </>
    );
  }
}
