import { Typography } from "@mui/material";


const appId = process.env.REACT_APP_DJI_ID;
const appKey = process.env.REACT_APP_DJI_KEY;
const appLicense = process.env.REACT_APP_DJI_LICENSE;

export default function JSBridge(props : any) {

  window.djiBridge.platformVerifyLicense(appId,appKey,appLicense)
  
    const isVerified = window.djiBridge.platformIsVerified()
    window.djiBridge.platformSetInformation("Avetics Cloud", "Drone Telemetry Workspace","Drone Connection")

    window.djiBridge.onBackClick = () => {return true;}
  const param = {
    host: "tcp://128.199.223.87:1883",						// mqtt address, example: tcp://xx.xx.xx.xx:xxx
	// js interface for connection status callbacks
    username: "Av3tic5Dr0n3",
    password: "Av3tics123123"
  }
 
  console.log("Component Loading ", window.djiBridge.platformIsComponentLoaded("thing"))
  const mqttConnectionStatus = JSON.parse(window.djiBridge.thingGetConnectState()); //Get connection status if 0 means success
  if(props.switch == false) //if switch not clicked, then mqtt will not connect
  {

    if( JSON.parse(window.djiBridge.platformIsComponentLoaded("thing")).data == true)
        {
          //UNLOAD component ONLY if it was loaded in the first place
          console.log("[-] Disconnecting Drone from MQTT Server")
          window.djiBridge.platformUnloadComponent("thing") //Stop MQTT connection if Switch is OFF
        }
  }
    if(props.switch == true)  window.djiBridge.platformLoadComponent("thing",JSON.stringify(param)) //MQTT module
  const droneId = JSON.parse(window.djiBridge.platformGetAircraftSN())    //Drone ID 
  const controllerId = JSON.parse(window.djiBridge.platformGetRemoteControllerSN()) // Controller ID
console.log(mqttConnectionStatus)
  return (
    <>
    <Typography variant="h5">Drone ID : {droneId.data}</Typography >
    <Typography variant="h5"> Controller ID : {controllerId.data}</Typography >
    <Typography variant="h5"> Connection with MQTT : {mqttConnectionStatus.message}</Typography >

    </>
  )

 
  
}
