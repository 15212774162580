import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikState, useFormik } from 'formik';
import * as yup from 'yup';
import { registerAccount } from '../../helpers/Firebase';
import { useAuthState } from '../../hooks/context'
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserFormData} from '../../@types/types'
import styled from 'styled-components';
import { PasswordSharp } from '@mui/icons-material';
import {UserSignUpValidationSchema as ValidationSchema} from '../../helpers/ValidationSchema'
import { AxiosResponse } from 'axios';
import Copyright from '../../components/CopyRight';
const FormCSS = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  
`
const ErrorMessageWrapper = styled.div`
font-size:11px;
color:red;
margin-left:5;
`

export default function UserSignUp(props:any) {
  //Error msg OR successful
  const [error,setError] = useState();
  const auth = useAuthState()
  const navigate = useNavigate()
  

    //onSubmit : (values) => {
  const handleSubmit = (values : UserFormData,resetForm : any)  => {    
      //console.log("Submitting :",values)
      //values.users.map(user => user.email.toLowerCase()),values.users,
      registerAccount(values,auth)
      .then((result : any) => {
        console.log(result)
        result.data.arr.forEach((item : string[] )=> {
            if(item[0] != "success")
              toast.error(item[1])
            else
              registerStatusMsg(item[1])

        })
        
        //registerStatusMsg("Successful Registration")
      //  resetForm()
      })
      .catch((e : any) => {
        console.log(e)
        if(e.response){
        setError(e.response.data)
        
        if(e.response.data.code)
          toast.error(e.response.data.msg);
        
          else 
          toast.error("Undefined Error! Please Contact System Administrator")
        }
        })
    }
  const registerStatusMsg = (msg : string) => toast(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    }); //success notification

  return (
  
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <ToastContainer />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Formik
          initialValues = {{
            users : [
              {
                email : "",
                password:""
              }
            ]
        }}
          validationSchema={ValidationSchema}
          onSubmit={(values : UserFormData , {resetForm} : any) => handleSubmit(values,resetForm)}
     
          >
          {(formik) => {
            const {values,resetForm} = formik
            return(
          <Form >
            <FormCSS>
            <FieldArray name="users">
            {
                    (fieldArrayProps) => 
                    {
                      const { push, remove, form} = fieldArrayProps
                      const users = form.values.users
           
                      return (
                        <>
                      <div>
                      {
                        users.map((user : {email : string, password : string},index : number) => (
                       <div key={index} style={{justifyContent:'center'}}>
                        <ErrorMessageWrapper>
                          <ErrorMessage name={`users.${index}.email`}/>
                      </ErrorMessageWrapper>
                      
                       <Field  type='email' name={`users.${index}.email`} placeholder={`Email Id ${index + 1}`} />
                        
                       <span>
                          <Button onClick ={() => push('')}>+</Button> 
                          <Button onClick={() => {
                        if(users.length > 1)
                            remove(index)
                          }}>-</Button>
                        </span>
                      <ErrorMessageWrapper>
                          <ErrorMessage name={`users.${index}.password`} />
                      </ErrorMessageWrapper>

                       <Field type="password" name={`users.${index}.password`} placeholder={`Password`} />
                       
                      </div>
                        )) }
                      </div>
                      </>
                      
                    )
                    }
                 
                  } 

            </FieldArray>

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </FormCSS>
          </Form>
          )}}
          </Formik>
            
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => navigate('/admin/users')}
            >
              Back
            </Button>
          </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
  
  );
}