import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { AppBar, SwipeableDrawer } from '@mui/material';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { logOutUser } from '../hooks/actions';
import { useAuthDispatch } from '../hooks/context';

export default function AdminSideBar()
{
	const Navigate = useNavigate();

  const drawerWidth=150
  const dispatch = useAuthDispatch()
  const drawerContents =( 
  <> 
  <Typography variant="h1" align='center' >
    <Button 
    color='inherit'
    fullWidth
    onClick = {() => {Navigate("/admin/dashboard")}}
    >
      Dashboard
    </Button>
          </Typography>

          <Typography variant="h1" align='center'>
    <Button 
    color='inherit'
    fullWidth
    onClick = {() => {Navigate("/admin/users")}}
    >
      Users
    </Button>
          </Typography>
   <Typography variant="h1" align='center'>
    <Button 
    color='inherit'
    onClick = {() => {logOutUser(dispatch);Navigate("/")}}
    fullWidth
    >
      Log out
    </Button>
          </Typography>
    </>)

   return(
	 <>
       <CssBaseline />
       <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
      {drawerContents}
      </Drawer>
    </>
	)

}